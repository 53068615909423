// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leaguedevelopment-tsx": () => import("./../../../src/pages/leaguedevelopment.tsx" /* webpackChunkName: "component---src-pages-leaguedevelopment-tsx" */),
  "component---src-pages-person-tsx": () => import("./../../../src/pages/person.tsx" /* webpackChunkName: "component---src-pages-person-tsx" */),
  "component---src-pages-pltable-tsx": () => import("./../../../src/pages/pltable.tsx" /* webpackChunkName: "component---src-pages-pltable-tsx" */),
  "component---src-pages-progression-tsx": () => import("./../../../src/pages/progression.tsx" /* webpackChunkName: "component---src-pages-progression-tsx" */)
}

